import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import logo from "./assets/logo.png";
import avatar from "./assets/avatar.png";

import "./details.css";

const Capellan = (props) => {
	let params = useParams();
	var injectedData = props.data;
	var region = params.region;
	var matricula = params.matricula;
	
	const [loading, setLoading] = useState(false)
	const [hasError, setHasError] = useState(false)
	const [data, setData] = useState(injectedData ?? []);

    useEffect(() => {
		if(!injectedData) {
			setLoading(true);
			fetch("https://opensheet.elk.sh/1vahT7pWaAa_kJ1R2KFLZnayXZs_xemDwD4gbu3gtG1Q/" + region)
			.then(res => res.json())
			.then(json => {
				setLoading(false);
				const info = json.find( ({ MATRICULA }) => MATRICULA === matricula );
				setData(info);
			}).catch(() => {
				setHasError(true);
				setLoading(false);
			});
		}
    }, [region, matricula, injectedData]);

	if(loading) {
		return (
			<>
				<div className="d-flex justify-content-center spinner">
					<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
				</div>
				<div className="d-flex justify-content-center mb-3">
					<img draggable={false} src={logo} className="no-pointer" width="100px" alt="placa-capellan" />
				</div>
			</>
		);
	}

	if(hasError) {
		return (
			<>
				<div className="error-page-box mt-5">
					<p className="error-code">404</p>
					<div className="error-description m-0 mb-3">
						<span className="font-weight-bold">Region: </span>
						{region}
						<span className="mx-2"></span>
						<span className="font-weight-bold">Matricula: </span>
						{matricula}
					</div>
					<p className="error-title">CAPELLAN NO ENCONTRADO!</p>
				</div>
				<div className="d-flex justify-content-center mb-3">
					<img draggable={false} src={logo} className="no-pointer" width="100px" alt="placa-capellan" />
				</div>
			</>
		);
	}

	return (
		<>
			<div className="capellan-info-wrapper mb-3">
				<div className="capellan-info">
					<div className="capellan-status">VOLUNTARIO {data['ESTATUS']}</div>
					<div className="d-flex flex-column flex-md-row">
						<div className="d-flex flex-column font-weight-bolder p-0">
							<Portrait foto={data['FOTO']} />
							<span>MATRICULA</span>
							<span className="font-blue">{data['MATRICULA'] ?? 'N/A'}</span>
							<span className="mt-2 mt-md-4">PLACA</span>
							<span className="font-blue">{data['PLACA'] ?? 'N/A'}</span>
						</div>
						
						<div className="col-auto col-md">
							<div className="d-flex flex-column justify-content-center align-items-center align-items-md-start">
								<div className="d-flex flex-column mb-3">
									<span className="info-row-value chaplain-name">{data['NOMBRE'] ?? 'N/A'}</span>
									<span className="info-row-name chaplain-name">AGENTE CAPELLAN INTERNACIONAL</span>
								</div>
								<InfoRow nombre='RANGO' valor={data['RANGO']} />
								<InfoRow nombre='CARGO' valor={data['CARGO']} />
								<InfoRow nombre='REGION' valor={data['REGION']} />
								
								<div className="d-flex flex-column flex-md-row">
									<InfoRow nombre='FOLIO' valor={data['FOLIO']} />
									<InfoRow nombre='ZONA' valor={data['ZONA']} />
								</div>
								{
									data['BAJA'] !== '' && data['BAJA'] != null ?
									<InfoRow nombre='BAJA' valor={data['BAJA']} />
									: <InfoRow nombre='ALTA' valor={data['ALTA']} />
								}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-center mt-5 mb-3">
				<img draggable={false} src={logo} className="no-pointer" width="100px" alt="placa-capellan" />
			</div>
		</>
	);
}

const Portrait = (props) => {
	let foto = props.foto !== '' ? props.foto : avatar;
	return (
		<div className="portrait">
			<img src={foto} onError={(ev) => ev.target.src = avatar} alt="avatar" />
		</div>
	);
}

const InfoRow = (props) => {
	return (
		<div className="mb-3 d-flex flex-row">
			<div className="info-row-name-wrapper">
				<span className="info-row-name">{props.nombre}</span>
			</div>
			<span className="info-row-value">{props.valor !== '' && props.valor != null ? props.valor : 'N/A'}</span>
		</div>
	);
}

export default Capellan;
