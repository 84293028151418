import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useLocation, Outlet  } from "react-router-dom";
import placa from "./assets/placa.png";

import "./details.css";

const Landing = (props) => {
	let location = useLocation();

	const pages = [
		{ href: "/", text: "" },
		{ href: "#vision", text: "VISION" },
		{ href: "#quienes-somos", text: "¿QUÍENES SOMOS?" },
		{ href: "#eventos-y-noticias", text: "EVENTOS Y NOTICIAS" },
		{ href: "#contacto", text: "CONTACTO" },
	];

	const getNavigationMenu = () => {
		return (
			<Navbar variant="dark" expand="md">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="m-auto">
						{pages.map((page, idx) => (
							<Nav.Link key={`nav-item-${idx}`} active={page.href === location.pathname} href={`/${page.href}`}>
								{page.text}
							</Nav.Link>
						))}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	return (
		<>
			<div className="page-content">
				<main>
					<div className="main-header">
						{getNavigationMenu()}
						<div className="main-header-bar">
							<img draggable={false} className="no-pointer" src={placa} alt='placa-capellan' />
						</div>
						<h1 className="font-weight-bolder">INTERNATIONAL CHAPLAIN</h1>
						<p className="negative-margin">AGENTS IN FAVOUR OF SOCIAL PEACE A.C.</p>
						<div className="title-decoration"></div>
						<br />
						<br />
					</div>
					<Outlet />
				</main>
			</div>
			<section className="copyright py-4 text-center">
				<div className="container">
					<small>Copyright © International Chaplain 2019-{new Date().getFullYear()}</small>
				</div>
			</section>
		</>
	);
};

export default Landing;
