import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, DropdownButton, Dropdown, Modal } from 'react-bootstrap';
import { Base64 } from 'js-base64';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import QRCode from "react-qr-code";
import logo from "./assets/logo.png";
import Capellan from "./Capellan";

const CapellanBrowser = (props) => {
	const [password, setPassword] = useState();
	const [passwordReady, setPasswordReady] = useState(false);
	const [passwordError, setPasswordError] = useState();
	const [loading, setLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [regiones, setRegiones] = useState([]);
	const [region, setRegion] = useState('');
	const [capellanes, setCapellanes] = useState([]);
	const [capellan, setCapellan] = useState();

	const checkPassword = useCallback(async () => {
		let encodedPassrord = Base64.encode(password);
		let key = Base64.decode('UTJGd1pXeHNZVzRqTURBeA==');
		if(encodedPassrord === key) {
			setPassword();
			setPasswordReady(true);
		} else {
			setPasswordError('Contraseña incorrecta');
		}
	}, [password]);

    useEffect(() => {
		setLoading(true);
        fetch("https://opensheet.elk.sh/1vahT7pWaAa_kJ1R2KFLZnayXZs_xemDwD4gbu3gtG1Q/REGIONES")
		.then(res => res.json())
		.then(json => {
			setLoading(false);
			setRegiones(json.map((r) => r['REGIONES']));
		}).catch(() => {
			setHasError(true);
			setLoading(false);
		});
    }, []);

	useEffect(() => {
		if(region) {
			setLoading(true);
			fetch(`https://opensheet.elk.sh/1vahT7pWaAa_kJ1R2KFLZnayXZs_xemDwD4gbu3gtG1Q/${region}`)
			.then(res => res.json())
			.then(json => {
				setLoading(false);
				setCapellanes(json);
			}).catch(() => {
				setHasError(true);
				setLoading(false);
			});
		}
    }, [region]);

	var content = <></>;

	if(!passwordReady) {
		return (
			<div className="login-box justify-content-center">
				<div className="form-group justify-content-between">
					<label>Verifique su identidad</label>
					<input
					type="password"
					className="form-control"
					id="exampleInputPassword1"
					placeholder="Contraseña"
					onChange={(event) => setPassword(event.target.value)}
					/>
					<small className="text-danger form-text">
					{passwordError}
					</small>
					<div className="d-flex justify-content-end"><Button onClick={checkPassword}>Entrar</Button></div>
				</div>
			</div>
		);
	}

	if(loading) {
		content = (
			<>
				<div className="d-flex justify-content-center spinner">
					<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
				</div>
				<div className="d-flex justify-content-center mb-3">
					<img draggable={false} src={logo} className="no-pointer" width="100px" alt="placa-capellan" />
				</div>
			</>
		);
	} else if(hasError) {
		content = (
			<>
				<div className="error-page-box mt-5">
					<p className="error-code">404</p>
					<p className="error-description m-0 mb-3">
						<span className="font-weight-bold">Region: </span>
						{region}
					</p>
					<p className="error-title">CAPELLAN NO ENCONTRADO!</p>
				</div>
				<div className="d-flex justify-content-center mb-3">
					<img draggable={false} src={logo} className="no-pointer" width="100px" alt="placa-capellan" />
				</div>
			</>
		);
	} else if(capellan) {
		content = <Capellan key={capellan['MATRICULA']} data={capellan} />;
	} else {
		content =  (
			<>
				<div className="error-page-box mt-5">
					<p className="error-title">SELECCIONE UN CAPELLAN!</p>
				</div>
				<div className="d-flex justify-content-center mb-3">
					<img draggable={false} src={logo} className="no-pointer" width="100px" alt="placa-capellan" />
				</div>
			</>
		);
	}

	return (
		<>
			<div className="d-flex justify-content-center mb-1">
				<div className="error-page-box m-0">
					<div className="error-description">
						<DropdownButton className="d-inline-flex" autoFocus={false} title='Region' onSelect={(r) => {
							if(region !== r) {
								setCapellan();
							}
							setRegion(r);
						}}>{
							regiones.map(
								(region, idx) => <Dropdown.Item  key={`drop-r-${idx}`} eventKey={region} >{region}</Dropdown.Item>
							)
						}</DropdownButton>
						<span className="mx-2" />
						{region !== '' ? region : 'N/A'}
						<span className="mx-2" />
						<DropdownButton className="d-inline-flex" autoFocus={false} title='Matricula' onSelect={(c) => setCapellan(capellanes[c])}>{
						capellanes.length !== 0 ?	
						capellanes.map(
								(c, idx) => <Dropdown.Item  key={`drop-m-${idx}`} eventKey={idx} >{`${c['MATRICULA']} - ${c['NOMBRE']}`}</Dropdown.Item>
						) :
						<Dropdown.Item disabled>N/A</Dropdown.Item>
						}</DropdownButton>
						<span className="mx-2" />
						{region !== '' && capellan ? capellan['MATRICULA']: 'N/A'}
						{
							region !== '' && capellan ?
							<>
								<span className="mx-2" />
								<QRCodeGenerator
									matricula={capellan['MATRICULA']}
									nombre={capellan['NOMBRE']}
									url={`/c/${region}/${capellan['MATRICULA']}`}
								/>
							</> : <></>
						}
					</div>
				</div>
			</div>
			{content}
		</>
	);
}

const QRCodeGenerator = (props)=> {
	const fullPath = window.location.href;
	let encodedUrl = encodeURI(props.url);
	const location = useLocation();
	const finalUrl = fullPath.replace(location.pathname, encodedUrl);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const matricula = props.matricula;

	const downloadQR = useCallback(async () => {
		const canvas = await html2canvas(document.getElementById("qr-image"));
		const dataURL = canvas.toDataURL('image/png');
		downloadjs(dataURL, `${matricula}.png`, 'image/png');
	}, [matricula]);

	return (
		<>
			<Button onClick={handleShow}>QR</Button>
			<Modal show={show} onHide={handleClose}>
			<Modal.Body className="d-flex flex-column align-items-center info-row-name pb-0">
				<div className="d-flex flex-row">
				<Link to={encodedUrl} target="_blank">
					{props.matricula}<span className="mx-1">-</span>{props.nombre}
					</Link>
				</div>
				<div id="qr-image"><QRCode value={finalUrl} /></div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
				Cerrar
				</Button>
				<Button variant="primary" onClick={downloadQR}>
				Descargar
				</Button>
			</Modal.Footer>
			</Modal>
		</>
	);
}

export default CapellanBrowser;
