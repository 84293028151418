import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import "./Responsive.css";

import Landing from "./Landing";
import HomeContent from "./HomeContent";
import Capellan from "./Capellan";
import CapellanBrowser from "./CapellanBrowser";

function App() {
  return (
    <BrowserRouter> 
      <Routes>
        <Route path="/" element={<Landing />} >
          <Route index element={<HomeContent />} />
          <Route path="generador" element={<CapellanBrowser />} />
          <Route path="c/:region/:matricula" element={<Capellan />} />
          <Route
            path="*"
            element={
              <div className="error-page-box">
                <p className="error-code">404</p>
                <p className="error-title">PAGINA NO ENCONTRADA!</p>
                <p className="error-description">Lo sentimos, la página solicitada no se encuentra.</p>
              </div>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
