import React from "react";
import logo from "./assets/logo.png";

const HomeContent = (props) =>{
	return (
		<>
			<div id="vision" className="web-section sbg-cyan">
				<h2>VISION</h2>
				<p className="justify">
					Conformar y acreditar a los pastores y líderes con un alto compromiso en el ejercicio de la labor que se le ha encomendado como instrumentos de Dios, y como agentes capellanes con el único
					objetivo para Trabajar en bien de los más necesitados y de aquellos que están sufriendo situaciones de emergencia urgente, debidas a catástrofes naturales, epidemias, enfrentamientos bélicos o
					disturbios internos. Porque creemos que El capellán es un ministro y tiene una función importante en la sociedad La ministración significa ser responsables de lo que Dios nos ha dado. Desde el
					primer día de vida humana sobre la Tierra, somos ministradores de los bienes de Dios. El hombre fue creado para dominar sobre la creación (Génesis 1:28).
				</p>
			</div>
			<div id="quienes-somos" className="web-section">
				<h2>¿QUÍENES SOMOS?</h2>
				<p className="justify">
					Somos una organización sin fines de lucro establecida por Dios con el propósito de llevar alivio al necesitado, promoviendo su desarrollo humano integral, mostramos nuestro compromiso mediante
					obras de misericordia sin hacer distinción étnica, política o religiosa eclesiástica, prestando especial atención a las necesidades más inmediatas de las personas en situaciones de emergencia
					tales como: rescate, albergue o refugio, agua potable, saneamiento, ayuda alimentaria de apoyo nutricional, salud y apoyo psicológico. De forma permanente realizamos nuestros servicios en las
					cárceles, hospitales, asilos, orfanatos, centros de rehabilitaciones, funerales, Instituciones de gobierno etc.
				</p>
			</div>
			<div id="eventos-y-noticias" className="web-section sbg-cyan d-none d-md-block">
				<h2>EVENTOS Y NOTICIAS</h2>
				<div className="flex-centered">
					<div dangerouslySetInnerHTML={{ __html: '<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FAgentes-Capellanes-Internacionales-en-Favor-de-La-Paz-Social-SP-ACIA-100525127959148%2F&tabs=timeline&width=500&height=500&small_header=true&adapt_container_width=false&hide_cover=true&show_facepile=false" width="500" height="500" style="border: none; overflow: hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" />'}} />
				</div>
			</div>
			<div id="contacto" className="web-section mt-0 p-2">
				<h2>CONTÁCTANOS</h2>
				<div className="d-flex flex-column mt-4 font-crimson font-weight-bolder">
					<img draggable={false} src={logo} className="mb-3 align-self-center no-pointer" width="100px" alt="placa-capellan" />
					<span>S.P. ACIA</span>
					<span>AVENIDA ZACATECAS 1282, COLONIA PUEBLO NUEVO,</span>
					<span>MEXICALI, BAJA CALIFORNIA, MÉXICO</span>
					<span>C.P. 21120</span>
					<span>RFC: ICA 190320 A26</span>
					<span>TELEFONO: 686 149 46 23</span>
					<span>WWW.INTERNATIONALCHAPLAIN.ORG</span>
				</div>
			</div>
		</>
	);
}

export default HomeContent;